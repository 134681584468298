// import { useRef, useLayoutEffect } from 'react'
import * as React from 'react'

class IncludedFragment extends React.Component {
  static defaultProps = {
    fragment: '',
  }
  selfRef = null

  constructor(props) {
    super(props)
    this.selfRef = React.createRef()
  }

  componentDidMount() {
    const range = document.createRange()
    range.selectNode(this.selfRef.current)
    const documentFragment = range.createContextualFragment(this.props.fragment)
    this.selfRef.current.appendChild(documentFragment)
  }

  render() {
    return <div ref={this.selfRef} />
  }
}

export default IncludedFragment
