import * as React from 'react'
import { Grid, Button, Icon, Header, Form } from 'semantic-ui-react'
import { useHotkeys } from 'react-hotkeys-hook'

import HTMLText from './system-wide/html-text'
import IncludedFragment from './system-wide/included-fragment'
import Question from './question'
import strings from '../locales'

import './page.css'

const GridRow = Grid.Row
const GridColumn = Grid.Column

const HotKeys = ({ handleNextPage, handlePrevPage, enablePrev, enableNext }) => {
  useHotkeys('ctrl+left', handlePrevPage, { enabled: enablePrev })
  useHotkeys('ctrl+right', handleNextPage, { enabled: enableNext })
  useHotkeys('ctrl+esc', () => document?.activeElement?.blur(), { enableOnFormTags: true })
  return null
}

class Page extends React.PureComponent {
  static defaultProps = {
    forceTitles: false,
    includes: {},
  }

  render() {
    let pageContent = this.props.content.reduce((acc, question, i) => {
      if (typeof question.group === 'undefined') {
        let childQuestions = this.props.content.filter(q => {
          return typeof q.group !== 'undefined' && q.group === question.name
        })
        acc.push(
          <Question
            key={`question-${this.props.name}-${i}`}
            {...question}
            currentPageName={this.props.name}
            showQuestionNames={this.props.page_options.show_question_names || this.props.forceTitles}
            childQuestions={childQuestions}
          />
        )
      }
      return acc
    }, [])
    if (this.props.name in this.props.includes) {
      pageContent = [
        <div
          key="included_fragment"
          className="sixteen wide column resurvey question info"
          style={{ paddingBottom: 0 }}>
          <div className="ui padded grid">
            <div>
              <div className="sixteen wide mobile column">
                <IncludedFragment fragment={this.props.includes[this.props.name]} />
              </div>
            </div>
          </div>
        </div>,

        ...pageContent,
      ]
    }

    let showButtons =
      this.props.page_options.is_end_page === false &&
      this.props.name !== '__stop_survey' &&
      this.props.name !== '__outofquota_survey'

    let showBackButton = !this.props.isFirst && this.props.page_options.show_back_button

    return (
      <React.Fragment>
        <HotKeys
          handlePrevPage={this.props.handlePrevPage}
          handleNextPage={this.props.handleNextPage}
          enablePrev={showButtons && showBackButton}
          enableNext={showButtons && !this.props.isLast}
        />
        <Form autoComplete="off">
          <Grid padded>
            <GridRow className="page title container">
              {(this.props.page_options.show_page_title || this.props.forceTitles) && (
                <GridColumn>
                  <Header as="h2">
                    <HTMLText value={this.props.title} />
                  </Header>
                </GridColumn>
              )}
            </GridRow>
            <GridRow className={this.props.readOnly ? 'readonly-page-content' : ''}>{pageContent}</GridRow>
            {showButtons && (
              <GridRow>
                <GridColumn floated="left" width={8} style={{ paddingRight: '.5rem' }}>
                  {showBackButton === true && (
                    <Button
                      primary
                      icon
                      labelPosition="left"
                      onClick={this.props.handlePrevPage}
                      data-test="back"
                      style={{ minWidth: '9rem', maxWidth: '14rem', margin: 0 }}>
                      <Icon name="left arrow" />
                      {strings.back_button}
                    </Button>
                  )}
                </GridColumn>
                <GridColumn floated="right" width={8} style={{ textAlign: 'right', paddingLeft: '.5rem' }}>
                  {this.props.isLast === false && (
                    <Button
                      primary
                      icon
                      labelPosition="right"
                      onClick={this.props.handleNextPage}
                      data-test="next"
                      style={{ minWidth: '9rem', maxWidth: '14rem', margin: 0 }}>
                      {strings.next_button}
                      <Icon name="right arrow" />
                    </Button>
                  )}
                </GridColumn>
              </GridRow>
            )}
          </Grid>
        </Form>
      </React.Fragment>
    )
  }
}

export default Page
