// Action types
export const SET_INCLUDED_FRAGMENTS = 'survey/SET_INCLUDED_FRAGMENTS'

// Initial state
export const initialState = {
  includes: {},
}

// Reducer
export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_INCLUDED_FRAGMENTS:
      return {
        ...state,
        includes: action.payload,
      }
    default:
      return state
  }
}

// Action creators
export const setIncludedFragments = includes => ({
  type: SET_INCLUDED_FRAGMENTS,
  payload: includes,
})

export default reducer
